<template>
  <div class="common_sub_container">
    <loader v-if="loading"/>
    <h2 v-if="!loading">OUTBOUND TRANSACTIONS</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Courier Company
                  <span></span>
                </label>
                <select v-model="filters.delivery_provider">
                  <option value="">All</option>
                  <option
                    v-for="(carrier,i) in carriers"
                    :key="'carriers'+i"
                    :value="carrier.id"
                  >{{carrier.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Status
                  <span></span>
                </label>
                <select v-model="filters.status">
                  <option value="">All</option>
                  <option
                    v-for="(status,i) in statuses"
                    :key="`status-${i}`"
                    :value="status"
                  >{{status}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Client
                  <span></span>
                </label>
                <select v-model="filters.staff_member">
                  <option value="all">All</option>
                  <option value="kwm">KWM</option>
                  <option value="base client">Base</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
            </td>
            <td style="width: 10%;padding-top: 38px;">
              <a href="javascript:void(0)" :class="['link_bt', 'bt_save' ,{ 'disabled': search_btn.disabled }]"
                :aria-disabled="search_btn.disabled" @click="fetchReport" 
                :disabled="search_btn.disabled">{{ search_btn.value }}</a>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <div class="dashboard_row">
      <div class="second_row_cover1">
        <div class="dashboard-row-divider">
          <div class="dashboard_block dashboard_block3 first">
            <div class="pending_blocks_row">
              <div
                class="pending_block"
              >
                <img src="../../assets/dash_encode.png" />
                <p class="total numbers clr4">{{counters.job_created}}</p>
                <p class="total category">JOB CREATED</p>
              </div>
              <div class="pending_block">
                <img src="../../assets/dash_mailroom.png" />
                <p class="total numbers clr1">{{counters.courier_allocated}}</p>
                <p class="total category">COURIER ALLOCATED</p>
              </div>
            </div>
          </div>
          <div class="dashboard_block dashboard_block3">
            <div class="pending_blocks_row">
              <div
                class="pending_block"
              >

                <img src="../../assets/dash_courier.png" />
                <p class="total numbers clr2">{{counters.picked_up_mailroom}}</p>
                <p class="total category">PICKED UP - MAILROOM</p>
              </div>
              <div class="pending_block">
                <i class="material-icons">flag</i>
                <p class="total numbers ">{{counters.picked_up_courier}}</p>
                <p class="total category">PICKED UP  - COURIER</p>
              </div>
            </div>
          </div>
          <div class="dashboard_block dashboard_block3">
            <div class="pending_blocks_row">
              <div class="pending_block">
                <!-- <img src="../../assets/dash_mailroom.png" /> -->
                <img src="../../assets/dash_transport.png" />
                <p class="total numbers clr3">{{counters.delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="reports_to_print">
      <div class="despatch_summary_wrapper">
        <header>
          <h3>Outbound Transactions Report {{ auth().user_type != 'super_admin' ? ` - ${auth().branch}` : '' }}</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        
        <div class="despatch_summary_table_container">
          <table id="outbound_delivery_report_table">
            <thead>
              <tr>
                <td><b>Booking Reference</b></td>
                <td><b>Airway Bill No</b></td>
                <td><b>Booking date</b></td>
                <td><b>Booking time</b></td>
                <td><b>Booked by</b></td>
                <td><b>Pickup Location</b></td>
                <td><b>Collection Option</b></td>
                <td><b>Sender</b></td>
                <td><b>Recipient</b></td>
                <td><b>Destination company</b></td>
                <td><b>Destination city</b></td>
                <td><b>Destination country</b></td>
                <td><b>Supplier name</b></td>
                <td><b>Service</b></td>
                <td><b>Total items</b></td>
                <td><b>Total weight</b></td>
                <td><b>Chargeble weight</b></td>
                <td><b>Manifested date</b></td>
                <td><b>Manifested time</b></td>
                <td><b>Delivered date</b></td>
                <td><b>Delivered time</b></td>
                <td><b>Fourth party reference</b></td>
                <td><b>Sale Price</b></td>
                <td><b>Courier Price</b></td>
                <td><b>Fuji Markup</b></td>
                <td><b>Drake Markup</b></td>
              </tr>
            </thead>
            <tbody>
              <template v-if="reportDatas.length">
                <tr v-for="(reportData, key) in reportDatas" :key="key">
                  <td>
                    <!-- Booking Reference -->
                    {{ reportData.booking_prefix }}{{ reportData.booking_id }}
                  </td>
                  <td>
                    <!-- HAWB -->
                    {{ reportData.airway_bill }}
                  </td>
                  <td>
                    {{ reportData.created_at_timestamp | formatDate}}
                    <!-- Booking date -->
                  </td>
                  <td>
                    {{ reportData.created_at_timestamp | formatTime}}
                    <!-- Booking time -->
                  </td>
                  <td>
                    {{ reportData.requester_id ? reportData.requester.name : '' }}
                    <!-- Booked by -->
                  </td>
                  <td>
                    {{ reportData.pickup_location_report }}
                    <!-- Pickup Location -->
                  </td>
                  <td>
                    {{ reportData.collection_option }}
                      <!-- Collection Option -->
                  </td>
                  <td>
                    <p>{{ reportData.pickup_name }}</p>
                    <!-- Sender -->
                  </td>
                  <td>
                    <template v-if="'Postal' == reportData.delivery_service_type">
                      {{ reportData.recipient_name }}
                    </template>
                    <template v-else>
                      {{ reportData.delivery_name }}
                    </template>
                    <!-- Recipient -->
                  </td>
                  <td>
                    {{ reportData.delivery_company }}
                    <!-- Destination company -->
                  </td>
                  <td>
                    {{ reportData.delivery_suburb }}
                    <!-- Destination City -->
                  </td>
                  <td>
                    {{ reportData.delivery_country }}
                    <!-- Destination country -->
                  </td>
                  <td>
                    {{ reportData.provider_name ? reportData.provider_name.name : '' }}
                    <!-- Supplier name -->
                  </td>
                  <td>
                    {{reportData.delivery_service}}
                    <!-- Service level -->
                  </td>
                  <td>
                    <!-- Total items -->
                    {{reportData.booking_type == 'Messenger' ? 0 : (reportData.courier_items.length || reportData.item_qty)}}
                  </td>
                  <td>
                    {{ reportData.weight }}
                    <!-- Total weight -->
                  </td>
                  <td>
                    
                    {{ reportData.weight }}
                    <!-- Chargeble weight -->
                  </td>
                  <td>
                    {{ reportData.manifested_status ? reportData.manifested_status.date : '' }}
                    <!-- Manifested date -->
                  </td>
                  <td>
                    {{ reportData.manifested_status ? reportData.manifested_status.time : '' }}
                    <!-- Manifested time -->
                  </td>
                  <td>
                    {{ reportData.delivered_status ? reportData.delivered_status.date : '' }}
                    <!-- Delivered date -->
                  </td>
                  <td>
                    {{ reportData.delivered_status ? reportData.delivered_status.time : '' }}
                    <!-- Delivered time -->
                  </td>
                  <td>
                    <template v-if="'Postal' == reportData.delivery_service_type">
                      {{ reportData.reference_number }}
                    </template>
                    <template>
                      {{ reportData.con_no }}
                    </template>
                    <!-- Fourth party reference -->
                  </td>
                  <td>
                    $ {{ reportData.cost }}
                    <!-- Show sales price -->
                  </td>
                  <td>
                    $ {{ reportData.courier_rate }}
                    <!-- Courier Price -->
                  </td>
                  <td>
                    {{ (reportData.client === 'kwm' && (reportData.delivery_provider === 'STC' || reportData.delivery_provider === 'STARTRACK-TV' )) ? `$ ${Number(reportData.cost - reportData.courier_rate).toFixed(2)}` : '' }}
                    <!-- Fuji Markup -->
                  </td>
                  <td>
                    {{ (reportData.client === 'base client' || reportData.client === 'personal') ? `$ ${Number(reportData.cost - reportData.courier_rate).toFixed(2)}` : '' }}
                    <!-- Drake Markup -->
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="21" align="center">No Booking data</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
      ],
      filters: {
        from:moment(new Date()).format("YYYY-MM-DD"),
        to:moment(new Date()).format("YYYY-MM-DD"),
        delivery_provider:'',
        status:'',
        inout:'Outgoing',
        staff_member:'all'
      },
      counters: {
        job_created:0,
        courier_allocated:0,
        picked_up_mailroom:0,
        picked_up_courier:0,
        delivered:0,
      },
      carriers:[],
      statuses:[
        "Received",
        "Encoded",
        "Dispatched",
        "Scan Run",
        "Cancelled",
        "Duplicate",
        "Delivered",
        "Refused Delivery",
        "Not Available",
        "Created",
        "Booked",
        "Mail room",
        "In transit",
        "Picked Up"
      ],
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      api_url:'',
      search_btn: {
        value:'Search',
        disabled:false
      },
      reportDatas:[],
    }
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('LT')
    },
  },
  components: {
    flatPickr,
  },
  async created() {
    await this.$emit("breadCrumbData", ["Report", "Outbound Transactions"])
    await this.fetchCarrier()
    this.api_url = process.env.VUE_APP_API_URL;
  },
  methods: {
    dateChanged(daterange) {
      this.filters.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.filters.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    async fetchReport() {
      const url = "/api/reports/get-outbound-report" 

      this.search_btn.disabled = true;
      this.search_btn.value = 'Searching...';

      try {
        this.reportDatas = []
        this.counters = {
          job_created:0,
          courier_allocated:0,
          picked_up_mailroom:0,
          picked_up_courier:0,
          delivered:0,
        }
        const response = await this.axios.post(url, this.filters)
        this.reportDatas = response.data.data.list
        this.counters = response.data.data.counters
      } catch (e) {
        console.log(e)
      }

      this.search_btn.disabled = false;
      this.search_btn.value = 'Search';
    },
    fetchCarrier() {
      this.axios
        .get("/api/provider/get-providers-by-category/outbound")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/get-outbound-report/download?token=" +
          localStorage.token
      );
      Object.keys(this.filters).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.filters[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      var elementToPrint = document.getElementById('outbound_delivery_report_table');
      if (elementToPrint) {
        var printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write('<style>table {width: 100%; border-collapse: collapse;} td {border: 1px solid black; padding: 5px;}</style>');
        printWindow.document.write(elementToPrint.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
      }
    }
  }
}
</script>

<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped>
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#outbound_delivery_report_table {
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: auto;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}
.scoped_cell_width_adj td {
  width: 16.6%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>

<style scoped lang="scss" >

.dashboard_row {
  width: 100%;
  display: flex;
  cursor:default;
  .dashboard_block {
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #efefef;
  }
  .second_row_cover1 {
    width:100%;
  }
  .dashboard-row-divider{
    display: flex;
    .dashboard_block {
      border-top: 5px solid #06A5ED;
      width: 50%;
      height: 250px;
      margin-right: 20px;
      &.dashboard_block3 {
        border-top: 5px solid #06A5ED;
        width: 33.33%;
        height: 200px;
      }
      &.first {
        // margin-left:10%;
      }
      &.dashboard_block4 {
        border-top: 5px solid #06A5ED;
        // width: 20%;
        height: 200px;
      }
      &:last-child {
         margin-right: 0;
      }
    }
  }
}

.pending_blocks_row {
  display: flex;
  margin: auto;
  justify-content: space-around;
  .pending_block {
    // width: 30%;
    height: auto;
    text-align: center;
    position: relative;
    padding: 10px;
    i.material-icons {
      font-size: 50px;
      border: 2.75px solid ;
      border-radius: 50%;
      padding: 10px;
      margin-bottom: 3px;
    }
    .red {
      color:red;
      border-color:red;
    }
    cursor: pointer;
    .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      font-size: 16px;
      text-align: center;
      color: #fff;
      display: none;
      justify-content: center;
      flex-direction: column;
    }
    &:hover .overlay {
      display: flex;
    }
    img {
      display: block;
      width: 75px;
      height: 75px;
      margin: auto;
    }
    p.total {
      padding: 5px 0;
      text-align: center;
      font-size: 18px;
      &.numbers {
        font-size: 25px;
        font-weight: 600;
        &.clr1 {
          color:#ffcf01;
        }
        &.clr2 {
          color: #06A5ED;
        }
        &.clr3 {
          color: #49a942;
        }
        &.clr4 {
          color: #004a7c;
        }
      }
      &.category {
        font-size: 19px;
        color: #333;
      }
    }
  }
}
</style>