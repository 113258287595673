<template>
  <div class="common_sub_container">
    <h2>OUTBOUND POSTAL REPORTS</h2>
    <section>
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Date Range
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Messenger Allocation
                  <span></span>
                </label>
                <select v-model="post_data.messenger_allocation">
                  <option value="all">All</option>
                  <option v-for="(messenger, i) in messengers" :value="messenger.name">{{ messenger.name }}</option>
                  <!-- <option value="Bun Lim">Bun Lim</option>
                  <option value="Andrew Bryne">Andrew Bryne</option>
                  <option value="Tony Radocaj">Tony Radocaj</option>
                  <option value="Temp User">Temp User</option>
                  <option value="Raymond Harrison">Raymond Harrison</option> -->
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Status
                  <span></span>
                </label>
                <select   v-model="post_data.status">
                  <option value="all">All</option>
                  <option value="Received">Received</option>
                  <option value="Encoded">Encoded</option>
                  <option value="Dispatched">Dispatched</option>
                  <option value="Scan Run">Scan Run</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Duplicate">Duplicate</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Refused Delivery">Refused Delivery</option>
                  <option value="Not Available">Not Available</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Client
                  <span></span>
                </label>
                <select v-model="post_data.staff_member">
                  <option value="all">All</option>
                  <option value="kwm">KWM</option>
                  <option value="base client">Base</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
            </td>
            <td></td>
            <td>
              <br>
              <br>
              <a href="javascript:void(0)" class="link_bt bt_save float-right" @click="fetchReport">Search</a>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section  v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper"  v-if="Object.keys(reports).length > 0">
        <header>
          <h3></h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="summary_table_container" v-for="(report,key,i) in reports" :key="'delivery_report'+i">
          <!-- <h2>{{key}}</h2> -->
          <table id="inbound_delivery_report_table">
            <tr>
              <td><b>Controller Location</b></td>
              <td><b>Delivery Company</b></td>
              <td><b>Item Description</b></td>
              <td><b>Job Number</b></td>
              <td><b>AWB Number</b></td>
              <td><b>Collect From</b></td>
              <td><b>Deliver To</b></td>
              <td><b>User Booked</b></td>
              <td><b>Number of Items</b></td>
              <td><b>Current Progress</b></td>              
              <td><b>Signature Present</b></td>
              <th  style="width:250px">Joint Code</th>
              <td><b>Services Level</b></td>
              <td><b>Sender</b></td>  
              <td><b>Special Instruction</b></td>
              <td><b>POD Signee</b></td>
              <td><b>Client Name</b></td>
              <td><b>Service</b></td>  

              <td><b>Date Booked</b></td>
              <td><b>Time Booked</b></td>
              <td><b>Messenger Allocation</b></td>
              <td><b>Date Messenger Assigned</b></td>
              <td><b>Time Messenger Assigned</b></td>
              <td><b>POD Date</b></td>
              <td><b>POD Time</b></td>
              <td><b>Formula</b></td>
            </tr>
            <tr v-for="(courier,i) in report" :key="i">
                <td>{{courier.branch}}</td>
                <td>{{'Postal' == courier.booking_type ? 'AUS POST' : courier.provider_id}}</td>
                <td>{{courier.goods }}</td>
                <td>{{courier.airway_bill}}</td>
                <td>{{courier.reference_number}}</td>
                <td>{{courier.division}}</td>
                <td>
                  <template v-if="'Postal' == courier.booking_type">
                    <div>
                      {{ courier.recipient_name }}
                    </div>
                    </template>
                  <template v-else>
                    <div v-if="checkEmailDomain(courier.delivery_email)">
                        Deliver to Desk
                    </div>
                    <div v-else>
                        Deliver to MR/Reception
                    </div>
                    </template>
                </td>
                <td>{{courier.requester_name}}</td>
                <td>{{ 'Postal' == courier.booking_type ? 1 : courier.item_qty}}</td>
                <td>{{ 'Postal' == courier.booking_type ? 'Received at Mail Room' : courier.status}}</td>
                
                <td>
                    <div v-if="courier.delivered_status">
                        <span v-if="courier.delivered_status.signature">
                            <img loading="lazy" :src="courier.delivered_status.signature" height="40px" width="40px" @click="openImage(courier.delivered_status.signature)" />
                        </span>
                    </div> 
                </td> 
                <!-- <td>{{courier.created_at | formatDateCode}} - {{ courier.requester_name }}</td> -->
                <td>{{ courier.created_at.split(' ')[0] }} - {{ courier.requester_name }}</td>
                <td>{{ 'Postal' == courier.booking_type ? '' : '60mins'}}</td>
                <td>{{courier.pickup_name}}</td>
                <td>{{courier.pickup_instructions}}</td>
                <td>
                    <div v-if="courier.delivered_status">
                        {{courier.delivered_status.sender_or_receiver}}
                    </div>     
                </td>
                
                <td>{{courier.pickup_name}}</td>
                <td>{{ serviceType(courier)}}</td>
                <td>{{courier.created_at | formatDate}}</td>
                <td>{{courier.created_at | formatTime}}</td>
                <td>
                  <div v-if="courier.accept_status">
                      {{courier.accept_status.sender_or_receiver}}
                  </div> 
                  <div v-else-if="courier.scan_run_status">
                      {{courier.scan_run_status.sender_or_receiver}}
                  </div>  
                  <div v-else-if="courier.delivered_status">
                      {{courier.delivered_status.sender_or_receiver}}
                  </div>  
                </td>
                <td>
                  <div v-if="courier.accept_status">
                      {{courier.accept_status.date}}
                  </div> 
                  <div v-else-if="courier.scan_run_status">
                      {{courier.scan_run_status.date}}
                  </div>    
                </td>
                 <td>
                    <div v-if="courier.accept_status">
                        {{courier.accept_status.time}}
                    </div> 
                    <div v-else-if="courier.scan_run_status">
                        {{courier.scan_run_status.time}}
                    </div>    
                    <div v-else-if="courier.delivered_status">
                        {{courier.delivered_status.time}}
                    </div>    
                </td>
                <td>
                    <div v-if="courier.delivered_status">
                        {{courier.delivered_status.date}}
                    </div> 
                </td>
                <td>
                    <div v-if="courier.delivered_status">
                        {{courier.delivered_status.time}}
                    </div>  
                </td> 
                <td>
                    <div v-if="courier.delivered_status && courier.accept_status">
                        {{computeDeliveryTime(courier.delivered_status.date, courier.delivered_status.time, courier.accept_status.date, courier.accept_status.time)}}
                    </div>
                    <div v-else-if="courier.delivered_status && courier.scan_run_status">
                        {{computeDeliveryTime(courier.delivered_status.date, courier.delivered_status.time, courier.scan_run_status.date, courier.scan_run_status.time)}}
                    </div>  
                </td>
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="reports.length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "Reports",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      counters: {
        item_received:0,
        item_delivered:0,
        mail_run:0,
        delivery_not_started:0,
        refused_not_available:0,
        delivered_on_time:0,
      },
      carriers: [],
      costcenters: [],
      goods: [],
      services: [],
      divisions: [],
      units: [],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        status: "all",
        type: "postal",
        page: "postal",
        messenger_allocation: "all",
        staff_member: "all",
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      show_data: false,
      messengers:[],
    }
  },
	filters: {
		formatDate(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('DD/MM/YYYY')
		},
    formatTime(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('HH:MM')
		},
	},
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", "Outbound Postal Report"]);
    this.getMEssengerAllocation()
  },
  methods: {
    serviceType(courier) {
      if (!courier.client || 'kwm' == courier.client) {
        return "Outgoing KWM"
      }

      if (!courier.client || 'personal' == courier.client) {
        return "Outgoing Personal"
      }

      return "Outgoing base"
    },
    async getMEssengerAllocation() {
        const url = '/api/reports/get-messenger-allocation-users'
        try {
          const response = await this.axios.get(url)
          this.messengers = response.data.data
        } catch (e) {
          console.log(esss)
        }
    },
    computeDeliveryTime(delivered_date, delivered_time, accept_date, accept_time) {
      
        const date1 = moment(accept_date + " " +accept_time, "DD-MM-YYYY HH:mm:ss");
        const date2 = moment(delivered_date + " " +delivered_time, "DD-MM-YYYY HH:mm:ss");

        // Calculate the difference in milliseconds
        const diffMs = Math.abs(date2 - date1);

        // Convert milliseconds to days, hours, and minutes
        const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
        const diffHrs = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
        return diffDays + " Day(s) " + diffHrs + " Hour(s) " + diffMins + " Minute(s)";
    },
    openImage(imageUrl) {
        window.open(imageUrl, "_blank");
    },
    checkEmailDomain(email) {

        if (!email) {
          return false;
        }
        // Extract the domain from the email address
        var domain = email.substring(email.lastIndexOf("@") + 1);
        // Compare the domain with "kwm.com"
        if (domain === "pbs-aus.com") {
            return true;
        } else {
            return false;
        }
    },
    dateChanged(daterange) {
      this.resetData();
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    resetData() {
      this.show_data = false;
      this.reports = {};
    },
    fetchReport() {
      this.resetData();
      this.axios
        .post("/api/reports/get-inbound-report", this.post_data)
        .then(response => {
          this.show_data = true;
          this.reports = response.data.data.list;
          this.counters = response.data.data.counters;
        })
        .catch(error => {
          console.log(error);
        });
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/get-inbound-report/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      var elementToPrint = document.getElementById('inbound_delivery_report_table');
      if (elementToPrint) {
        var printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write('<style>table {width: 100%; border-collapse: collapse;} td {border: 1px solid black; padding: 5px;}</style>');
        printWindow.document.write(elementToPrint.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
      }
    }
  }
};
</script>
<style  lang="scss" scoped>
#inbound_delivery_report_table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    height: 500px;
    overflow-y: auto;
}
.flatpickr-calendar.lol {
  border: 5px solid red;
}
.dashboard_row {
  width: 100%;
  display: flex;
  cursor:default;
  .dashboard_block {
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #efefef;
  }
  .second_row_cover1 {
    width:100%;
  }
  .dashboard-row-divider{
    display: flex;
    .dashboard_block {
      border-top: 5px solid #06A5ED;
      width: 50%;
      height: 250px;
      margin-right: 20px;
      &.dashboard_block3 {
        border-top: 5px solid #06A5ED;
        width: 40%;
        height: 200px;
        margin-bottom: 40px;
      }
    }
  }
}

.pending_blocks_row {
  display: flex;
  margin: auto;
  justify-content: space-around;
  .pending_block {
    width: 30%;
    height: auto;
    text-align: center;
    position: relative;
    padding: 10px;
    i.material-icons {
      font-size: 50px;
      border: 2.75px solid ;
      border-radius: 50%;
      padding: 10px;
      margin-bottom: 3px;
    }
    .red {
      color:red;
      border-color:red;
    }
    cursor: pointer;
    .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      font-size: 16px;
      text-align: center;
      color: #fff;
      display: none;
      justify-content: center;
      flex-direction: column;
    }
    &:hover .overlay {
      display: flex;
    }
    img {
      display: block;
      width: 75px;
      height: 75px;
      margin: auto;
    }
    p.total {
      padding: 5px 0;
      text-align: center;
      font-size: 18px;
      &.numbers {
        font-size: 25px;
        font-weight: 600;
        &.clr1 {
          color:#ffcf01;
        }
        &.clr2 {
          color: #06A5ED;
        }
        &.clr3 {
          color: #49a942;
        }
        &.clr4 {
          color: #004a7c;
        }
      }
      &.category {
        font-size: 19px;
        color: #333;
      }
    }
  }
}
</style>
<style  scoped>
.link_bt {
  margin-left: 10px;
}

.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}
.summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>