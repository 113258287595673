<template>
  <div class="common_sub_container">
    <loader v-if="loading"/>
    <h2 v-if="!loading">MAILROOM BOOKING</h2>
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <section class="search_section" v-if="!loading">
      <h5>Enter Con No</h5>
      <input type="text" v-model="booking_id" @keyup.enter="fetchBookingDetail" placeholder="Search" @keydown="nameKeydown($event)"/>
    </section>
    <section v-if="!loading">
      <div class="form_section1">
        <table>
          <tr>
            <td>
            <ValidationProvider name="receiver name" rules="required|max:100" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
                <label>
                  Receiver Name
                  <span>*</span>
                </label>
                <input type="text" placeholder="Receiver Name Character Limit 100" :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'" v-model="bookingdetail.delivery_name" @focus="show_requester_address = true" @blur="hideRequesterAddress" :maxlength="max100" required="required"/>
                <div class="dynamic_data_wrapper" v-if="show_requester_address">
                  <span 
                        v-for="(address,i) in filterAddress" 
                        :key="'receiver_address'+i"
                        @click="populateAddress(address)"
                        >
                        {{address.name}} 
                        <template v-if="address.user_assoc"> <div style="float:right;"> {{ address.user_assoc.secretary ? "!" :'' }} </div></template>
                      </span>
                </div>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <!-- <ValidationProvider name="company name" rules="required|max:30" v-slot="{ errors }" slim> -->
                <div class="label_and_element_wrapper">
                <label>
                  Company Name
                  <!-- <span>*</span> -->
                </label>
                <input type="text" placeholder="Company Name Character Limit 50" v-model="bookingdetail.delivery_company"  :maxlength="max7"/>
              </div>
            <!-- </ValidationProvider> -->
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Branch Name
                </label>
                <input type="text" placeholder="Branch Name Character Limit 30" v-model="bookingdetail.delivery_branch" :maxlength="max2" @keydown="nameKeydown($event)"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Department Name
                </label>
                <select v-model="bookingdetail.delivery_department">
                  <option value="">Select Department</option>
                  <option v-for="(department,i) in departments" :key="'delivery_department_code'+i" :value="department.department_code">{{department.department_name}}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="address_1" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Desk Number
                </label>
                <input type="text" placeholder="Desk Number Character Limit 60" v-model="bookingdetail.delivery_desk" :maxlength="max" />
              </div>
            </ValidationProvider>
            </td>
            <td>
              <!-- <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim> -->
              <div class="label_and_element_wrapper">
                <label>
                  Floor Number
                </label>
                <input type="text" placeholder="Floor Number Character Limit 60" v-model="bookingdetail.delivery_floor" :maxlength="max" />
              </div>
            <!-- </ValidationProvider> -->
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="address_1" rules="required|max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address 1
                  <span>*</span>
                </label>
                <input type="text" placeholder="Address 1 Character Limit 100" v-model="bookingdetail.delivery_address_1" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <!-- <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim> -->
              <div class="label_and_element_wrapper">
                <label>
                  Address 2
                  <!-- <span>*</span> -->
                </label>
                <input type="text" placeholder="Address 2 Character Limit 100" v-model="bookingdetail.delivery_address_2" :maxlength="max100" />
              </div>
            <!-- </ValidationProvider> -->
            </td>
          </tr>
          <tr>
            <td>
              <!-- <ValidationProvider name="Postal code" rules="required|max:10" v-slot="{ errors }" slim> -->
              <div class="label_and_element_wrapper">
                <label>
                  Postal Code
                  <!-- <span>*</span> -->
                </label>
                <input type="text" placeholder="Postal Code  Character Limit 10" v-model="bookingdetail.delivery_postal_code" :maxlength="max3"/>
              </div>
            <!-- </ValidationProvider> -->
            </td>
            <td>
              <!-- <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim> -->
              <div class="label_and_element_wrapper">
                <label>
                  City
                  <!-- <span>*</span> -->
                </label>
                <input type="text" placeholder="City Character Limit 100" v-model="bookingdetail.delivery_city" :maxlength="max100" />
              </div>
            <!-- </ValidationProvider> -->
            </td>
          </tr>
          <tr>
            <td>
               <ValidationProvider name="Suburb" rules="required|max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Suburb
                  <span>*</span>
                </label>
                <input type="text" placeholder="Suburb Character Limit 100" v-model="bookingdetail.delivery_suburb" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="state" rules="required|max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  State/Province
                  <span>*</span>
                </label>
                <input type="text" placeholder="State/Province Character Limit 100" v-model="bookingdetail.delivery_state" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="country" rules="required|max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Country
                  <span>*</span>
                </label>
                <input type="text" placeholder="Country Character Limit 30" v-model="bookingdetail.delivery_country" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="Phone" rules="required|max:20" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Phone
                  <span>*</span>
                </label>
                <input type="text" placeholder="Phone Character Limit 20" v-model="bookingdetail.delivery_phone" :maxlength="max1" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Mobile
                </label>
                <input type="text" placeholder="Mobile Character Limit 20" v-model="bookingdetail.delivery_mobile" :maxlength="max1"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Email
                </label>
                <input type="text" placeholder="Email Character Limit 100" v-model="bookingdetail.delivery_email" :maxlength="max100"/>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <section v-if="!loading && has_secretary">
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <ValidationProvider name="country" rules="required|max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Secretary Name
                  <span>*</span>
                </label>
                <input disabled type="text" placeholder="Secretary Name" v-model="secretary_name" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="Phone" rules="required|max:20" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Secretary Phone
                  <span>*</span>
                </label>
                <input disabled type="text" placeholder="Secretary Phone" v-model="secretary_mobile" :maxlength="max1" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Secretary Desk
                </label>
                <input disabled type="text" placeholder="Secretary Desk" v-model="secretary_desk" :maxlength="max1"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Secretary Floor
                </label>
                <input disabled type="text" placeholder=" Secretary Floor" v-model="secretary_floor" :maxlength="max100"/>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    
    <section v-if="!loading">
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <ValidationProvider name="Sender" rules="required|max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Sender
                  <span>*</span>
                </label>
                <input type="text" placeholder="Sender Character Limit 100" v-model="bookingdetail.requester_name" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="package" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Package Type
                  <span>*</span>
                </label>
                <select  v-model="bookingdetail.type_of_good" :class="errors[0] ? 'warning-border':''">
                  <option value="" disabled>Select package type</option>
                  <option v-for="(good,i) in goods" :key="'goods'+i" :value="good.id">{{good.type}}</option>
                </select>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Delivery Provider
                  <span>*</span>
                </label>
                <select tabindex="1" v-model="bookingdetail.incoming_courier">
                  <option value="" disabled>Select</option>
                  <option v-for="(provider,i) in providers" :key="'providers'+i" :value="provider.carrier_code">{{provider.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>Con No/Ref</label>
                <input tabindex="1" type="text" placeholder="AirWay Bill/Ref Character Limit 150" v-model="bookingdetail.airway_bill" :maxlength="max150"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="Number of Items" rules="required|max:5" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Number of Items
                  <span>*</span>
                </label>
                <input type="text" placeholder="Number of Items Character Limit 5" v-model="bookingdetail.item_qty" :maxlength="max5" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="Item Description" rules="max:100" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Item Description
                  <!-- <span>*</span> -->
                </label>
                <input type="text" placeholder="Item Description Character Limit 100" v-model="bookingdetail.goods_description" :maxlength="max100" :class="errors[0] ? 'warning-border':''"/>
              </div>
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="label_and_element_wrapper">
                <label>
                  Special Instruction
                </label>
                <textarea tabindex="2" placeholder="Special Instruction Character Limit 100" v-model="bookingdetail.pickup_instructions" :maxlength="max100"></textarea>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Mailroom storage location
                </label>
                <select  v-model="bookingdetail.mailroom_location" @change="setDefaultEmailTemplate">
                  <option value="" disabled>Select</option>
                  <option v-for="(mailroomlocation,i) in mailroomlocations" :key="'mailroomlocations'+i" :value="mailroomlocation.id">{{mailroomlocation.inbound_location}} </option>
                </select>
                <!-- <select  v-model="bookingdetail.mailroom_location" @change="setDefaultEmailTemplate">
                  <option value="" disabled>Select</option>
                  <option v-for="(mailroomlocation,i) in mailroomlocations" :key="'mailroomlocations'+i" :value="mailroomlocation.id">{{mailroomlocation.outbound_location}}</option>
                </select> -->
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Email Template
                                    </label>
                                    <select tabindex="3" v-model="bookingdetail.email_template">
                                        <!-- <option value="" disabled>Select</option>-->
                                        <option v-for="(emailtemplate, i) in emailtemplates" :key="'emailtemplates' + i"
                                            :value="emailtemplate.id">{{ emailtemplate.title }}</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>Delivery Location</label>
                                    <select v-model="bookingdetail.delivery_location">
                                        <option value="">Select</option>
                                        <option v-for="(deliverylocation, i) in deliverylocations"
                                            :key="'deliverylocations' + i" :value="deliverylocation.id">
                                            {{ deliverylocation.inbound_location }}</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Scanned By
                                    </label>
                                    <input tabindex="4" type="text" placeholder="Scanned By Character Limit 30"
                                        v-model="bookingdetail.scanned_by" :maxlength="max2" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Scanned Date
                                    </label>
                                    <flat-pickr :config="configDate" placeholder="Select Date" tabindex="5" name="birthdate"
                                        v-model="bookingdetail.scanned_date"></flat-pickr>
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Scanned Time
                                    </label>
                                    <flat-pickr :config="configTime" tabindex="6" placeholder="Select Time" name="birthtime"
                                        v-model="bookingdetail.scanned_time"></flat-pickr>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <tr>
                          <td>
                              <div class="label_and_element_wrapper">
                                  <label>Client</label>
                                  <div style="display: flex; align-items: center; gap: 10px;">
                                      <label for="kwm" style="margin: 0; vertical-align: middle; font-weight: normal; font-size: 16px;">
                                          <input 
                                              type="radio" 
                                              id="kwm" 
                                              value="kwm" 
                                              v-model="bookingdetail.client"
                                              style="transform: scale(1.5); margin-right: 5px;"
                                          >
                                          KWM
                                      </label>
                                      
                                      <!-- <label for="base_client" style="margin: 0; vertical-align: middle; font-weight: normal; font-size: 16px;">
                                          <input 
                                              type="radio" 
                                              id="base_client" 
                                              value="base client" 
                                              v-model="bookingdetail.client"
                                              style="transform: scale(1.5); margin-right: 5px;"
                                          >
                                          Base Client
                                      </label> -->
                                      
                                      <label for="personal" style="margin: 0; vertical-align: middle; font-weight: normal; font-size: 16px;">
                                          <input 
                                              type="radio" 
                                              id="personal" 
                                              value="personal" 
                                              v-model="bookingdetail.client"
                                              style="transform: scale(1.5); margin-right: 5px;"
                                          >
                                          Personal
                                      </label>
                                  </div>
                              </div>
                          </td>
                      </tr>
                </div>
            </section>
        </ValidationObserver>

        <section class="centered" v-if="!loading">
            <a class="link_bt bt_cancel" tabindex="7" @click="$router.push('/pages/Dashboard')">Cancel</a>
            <a class="link_bt bt_save" @click="submit" :disabled="save_btn.disabled">{{ save_btn.value }}</a>
        </section>
        <div class="black-overlay address-book-modal" v-if="address_book_modal">
            <div class="box-modal">
                <div class="body">
                    <AddressBookModal v-if="address_book_modal" @closeModal="address_book_modal = false"
                        @selectedAddress="selectedAddress" :hideFilter="true" />
                </div>
            </div>
        </div>

        <div class="black-overlay" v-if="fetched_booking">
            <div class="box-modal receiptPops">
                <div class="body">
                    <MailroomReceipt @closeModal="resetData" :data="fetched_booking" v-if="fetched_booking" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import MailroomReceipt from "./MailroomReceipt.vue";
import AddressBookModal from "./AddressBookModal";
import { ConfigurationEnum } from "@/enums";
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "MailRoom",
    components: {
        flatPickr,
        MailroomReceipt,
        AddressBookModal
    },
    computed: {
        filterAddress() {
            return this.address_list.filter(element => {
                if (element.name.toLowerCase().includes(this.bookingdetail.delivery_name.toLowerCase())) {
                    return element;
                }
            })
        },
        ...mapGetters({
            configurations: 'configuration/configurations',
        }),
    },
    data() {
        return {
            // enableTime: true,
            max: 60,
            max2: 30,
            max4: 70,
            max3: 10,
            max1: 20,
            max5: 5,
            max6: 300,
            max7: 50,
            max100: 100,
            max150: 150,
      // enableTime: true,
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            configDate: {
                format: "d-m-Y",
                altFormat: "d-m-Y",
                altInput: true
            },
            showMailroomReceipt: false,
            booking_id: "",
            bookingdetail: {
                requester_name: "",
                type_of_good: "",
                incoming_courier: "",
                airway_bill: "",
                delivery_name: "",
                delivery_company: "",
                delivery_branch: "",
                delivery_department: "",
                delivery_desk: "",
                delivery_floor: "",
                delivery_address_1: "",
                delivery_address_2: "",
                delivery_postal_code: "",
                delivery_city: "",
                delivery_suburb: "",
                delivery_state: "",
                delivery_country: "",
                delivery_phone: "",
                delivery_mobile: "",
                delivery_email: "",
                goods_description: "",
                pickup_instructions: "",
                mailroom_location: "",
                delivery_location: "",
                scanned_by: "",
                scanned_date: this.moment(new Date()).format("YYYY-MM-DD"),
                scanned_time: this.moment(new Date()).format("HH:mm:ss"),
                email_template: "",
                item_qty: "",
                item_type: "",
                secretary_id: "",
                client: "kwm", // Default value
            },
            address_list: [],
            departments: [],
            goods: [],
            emailtemplates: [],
            mailroomlocations: [],
            deliverylocations: [],
            providers: [],
            fetched_booking: "",
            show_requester_address: false,
            loading: false,
            save_btn: {
                disabled: false,
                value: "SAVE"
            },
            user: {},
            address_book_modal: false,
            extraAddressFieldEnabled: 1,
            has_secretary: false,
            secretary_name: "",
            secretary_mobile: "",
            secretary_desk: "",
            secretary_floor: "",
        };
    },
    async created() {
        this.$emit("breadCrumbData", ["Mailroom", "Encode Inbound Shipments"]);
        await this.setConfigurations();
        await this.initializeData();
        if (this.$route.params.id) {
            this.booking_id = this.$route.params.id;
            this.fetchBookingDetail();
        }
        this.extraAddressFieldEnabled = this.configurations[ConfigurationEnum.EXTRA_ADDRESS_FIELD];
    },
    methods: {
        ...mapActions({
          setConfigurations: 'configuration/setConfigurations',
        }),
        initializeData() {
            this.fetchDepartment();
            this.fetchPackageType();
            this.fetchDeliveryProvider('Messenger');
            this.fetchAddresses();
            this.fetchMailroomLocation();
            this.fetchUser();
        },
        nameKeydown(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        resetData() {
            Object.assign(this.$data, this.$options.data.apply(this));
            this.initializeData();
        },
        fetchUser() {
            this.axios.get("api/user")
                .then(response => {
                    this.bookingdetail.scanned_by = response.data.user.name;
                });
        },
        selectedAddress(value) {
            this.bookingdetail.delivery_name = value.name;
            this.bookingdetail.delivery_company = value.company_name;
            this.bookingdetail.delivery_branch = value.branch_name;
            this.bookingdetail.delivery_department = value.department ? value.department.department_name : "";
            this.bookingdetail.delivery_desk = value.desk;
            this.bookingdetail.delivery_floor = value.floor;
            this.bookingdetail.delivery_address_1 = value.address_1;
            this.bookingdetail.delivery_address_2 = value.address_2;
            this.bookingdetail.delivery_postal_code = value.postal_code;
            this.bookingdetail.delivery_city = value.city;
            this.bookingdetail.delivery_suburb = value.suburb;
            this.bookingdetail.delivery_state = value.state;
            this.bookingdetail.delivery_country = value.country;
            this.bookingdetail.delivery_phone = value.phone;
            this.bookingdetail.delivery_mobile = value.mobile;
            this.bookingdetail.delivery_email = value.email;
            this.populateSecretary(value.email);
        },
        populateAddress(data) {
            this.bookingdetail.secretary_id = ''
            this.bookingdetail.delivery_name = data.name;
            this.bookingdetail.delivery_company = data.company_name;
            this.bookingdetail.delivery_branch = data.branch_name;
            this.bookingdetail.delivery_department = data.department ? data.department.department_name : "";
            this.bookingdetail.delivery_desk = data.desk;
            this.bookingdetail.delivery_floor = data.floor;
            this.bookingdetail.delivery_address_1 = data.address_1;
            this.bookingdetail.delivery_address_2 = data.address_2;
            this.bookingdetail.delivery_postal_code = data.postal_code;
            this.bookingdetail.delivery_city = data.city;
            this.bookingdetail.delivery_suburb = data.suburb;
            this.bookingdetail.delivery_state = data.state;
            this.bookingdetail.delivery_country = data.country;
            this.bookingdetail.delivery_phone = data.phone;
            this.bookingdetail.delivery_mobile = data.mobile;
            this.bookingdetail.delivery_email = data.email;
            if (data.user_assoc) {
              this.bookingdetail.secretary_id = data.user_assoc.secretary ? data.user_assoc.secretary_id : '';
            }
            this.show_requester_address = false;
            this.populateSecretary(data.email);
        },
        populateSecretary(email) {
          if (email) {
            this.axios.get("/api/user/get-user-by-email/" + email)
              .then(response => {
                this.has_secretary = response.data.has_secretary;
                if (response.data.has_secretary) {
                  this.secretary_name = response.data.data.secretary.name;
                  this.secretary_mobile = response.data.data.secretary.phone;
                  this.secretary_desk = response.data.data.secretary.desk;
                  this.secretary_floor = response.data.data.secretary.floor;
                }
              })
              .catch((error) => {
                console.log(error)
              });
          }
        },
        hideRequesterAddress() {
            setTimeout(() => {
                this.show_requester_address = false;
            }, 200);
        },
        fetchBookingDetail() {
            if (this.booking_id) {
                this.axios.get("/api/courierbooking/fetchbookingiddetails/" + this.booking_id)
                    .then(response => {
                        const bookingDetail = response.data.booking_details;
                        const statuses = ['Dispatched', 'Scan Run', 'Delivered', 'Delivery Started'];

                        if (bookingDetail.status == 'Received' && this.auth().user_type != 'hub_admin') {
                            this.toast.error(`This AWB number already exists in the system as ${bookingDetail.airway_bill}. Please continue and add a new job.`);
                            return;
                        }

                        if (statuses.includes(bookingDetail.status) && this.auth().user_type != 'hub_admin') {
                            this.toast.error(`This AWB number is already in "${bookingDetail.status}" status. Please use a different reference.`);
                            return;
                        }

                        this.bookingdetail.delivery_name = bookingDetail.delivery_name;
                        this.bookingdetail.delivery_company = bookingDetail.delivery_company;
                        this.bookingdetail.delivery_branch = bookingDetail.delivery_branch;
                        this.bookingdetail.delivery_department = bookingDetail.delivery_department;
                        this.bookingdetail.delivery_desk = bookingDetail.delivery_desk;
                        this.bookingdetail.delivery_floor = bookingDetail.delivery_floor;
                        this.bookingdetail.delivery_address_1 = bookingDetail.delivery_address_1;
                        this.bookingdetail.delivery_address_2 = bookingDetail.delivery_address_2;
                        this.bookingdetail.delivery_postal_code = bookingDetail.delivery_postal_code;
                        this.bookingdetail.delivery_city = bookingDetail.delivery_city;
                        this.bookingdetail.delivery_suburb = bookingDetail.delivery_suburb;
                        this.bookingdetail.delivery_state = bookingDetail.delivery_state;
                        this.bookingdetail.delivery_country = bookingDetail.delivery_country;
                        this.bookingdetail.delivery_phone = bookingDetail.delivery_phone;
                        this.bookingdetail.delivery_mobile = bookingDetail.delivery_mobile;
                        this.bookingdetail.delivery_email = bookingDetail.delivery_email;
                        this.bookingdetail.requester_name = bookingDetail.requester_name;
                        this.bookingdetail.type_of_good = bookingDetail.type_of_good;
                        this.bookingdetail.airway_bill = bookingDetail.airway_bill;
                        this.bookingdetail.item_qty = bookingDetail.item_qty;
                        this.bookingdetail.goods_description = bookingDetail.goods_description;
                        this.bookingdetail.pickup_instructions = bookingDetail.pickup_instructions;
                        this.bookingdetail.item_type = bookingDetail.item_type;
                        this.bookingdetail.client = bookingDetail.client;
                        if (bookingDetail.email_template) {
                            this.bookingdetail.email_template = bookingDetail.email_template;
                        }
                        if (bookingDetail.mailroom_location) {
                            this.bookingdetail.mailroom_location = bookingDetail.mailroom_location;
                        }
                        if (bookingDetail.incoming_courier != null) {
                            this.bookingdetail.incoming_courier = bookingDetail.incoming_courier;
                        } else {
                            this.bookingdetail.incoming_courier = bookingDetail.carrier_code;
                        }
                    })
                    .catch((error) => {
                        this.address_book_modal = true;
                        this.bookingdetail.airway_bill = this.booking_id;
                        this.booking_id = "";
                    });
            }
        },
        fetchDepartment() {
            if (this.bookingdetail.delivery_branch) {
                this.axios.get("/api/location/showdepartment/" + this.bookingdetail.delivery_branch)
                    .then(response => {
                        this.departments = response.data.department;
                        if (this.departments.length > 0) {
                            this.bookingdetail.delivery_department = this.departments[0].department_code;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                this.departments = [];
            }
        },
        fetchPackageType() {
            this.axios.get("/api/goods/quotebook")
                .then(response => {
                    this.goods = response.data.goods;
                    if (!this.$route.params.id && this.goods.length > 0) {

                        this.bookingdetail.type_of_good = this.goods[1].id;

                    }

                    // if(!this.$route.params.id && this.goods.length > 0){
                    //   this.bookingdetail.type_of_good = this.goods[0].id;
                    // }
                })
                .catch(() => {
                    this.toast.error();
                });
        },
        fetchDeliveryProvider() {
            this.axios.get("/api/provider/inbound")
                .then(response => {
                    this.providers = response.data.providers;
                    if (!this.$route.params.id && this.providers.length > 0) {
                        this.bookingdetail.incoming_courier = this.providers[0].carrier_code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        fetchMailroomLocation() {
            this.axios.get("/api/division/divisionlocations")
                .then(response => {
                    this.mailroomlocations = response.data.divisions.division_locations;
                    if (this.mailroomlocations.length > 0) {
                        if (response.data.divisions.default_mailroom_location_id) {
                            this.bookingdetail.mailroom_location = response.data.divisions.default_mailroom_location_id;
                        } else {
                            this.bookingdetail.mailroom_location = response.data.divisions.division_locations[0].id;
                        }
                    }
                    this.emailtemplates = response.data.divisions.email_templates;
                    if (this.mailroomlocations.length > 0 && this.emailtemplates.length > 0) {
                        this.setDefaultEmailTemplate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        setDefaultEmailTemplate() {
            let mailroomlocation = this.mailroomlocations.find(element => element.id == this.bookingdetail.mailroom_location);
            if (this.emailtemplates.length > 0) {
                if (mailroomlocation) {
                    this.bookingdetail.email_template = mailroomlocation.default_mailroom_email_template_id;
                } else {
                    this.bookingdetail.email_template = this.emailtemplates[0].id;
                }
            }
        },
        fetchDeliveryLocation() {
            if (this.bookingdetail.delivery_department && this.bookingdetail.delivery_branch) {
                this.axios.get("/api/location/getlocationByid/" + this.bookingdetail.delivery_department + "/" + this.bookingdetail.delivery_branch)
                    .then(response => {
                        if (response.data.locations) {
                            this.deliverylocations = response.data.locations.locationdeptbranch;
                            if (this.deliverylocations.length > 0) {
                                if (response.data.locations.default_location_id) {
                                    this.bookingdetail.delivery_location = response.data.locations.default_location_id;
                                } else {
                                    this.bookingdetail.delivery_location = response.data.locations.locationdeptbranch[0].id;
                                }
                            } else {
                                this.bookingdetail.delivery_location = "";
                            }
                        } else {
                            this.deliverylocations = [];
                            this.bookingdetail.delivery_location = "";
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                this.deliverylocations = [];
                this.bookingdetail.delivery_location = "";
            }
        },
        fetchAddresses() {
            this.axios.post("api/address/list")
                .then(response => {
                    this.address_list = response.data.msg;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async submit() {
            this.$refs.observer.validate();
            this.save_btn.disabled = true;
            this.save_btn.value = "SAVING...";

            if (this.$route.params.id) {

                try {
                    const response = await this.axios.post("/api/courierbooking/createupdatebookingdetails/" + this.$route.params.id, this.bookingdetail);
                    this.toast.success(response.data.msg);
                    if (response.data.booking_details.booking_type == "Mailroom")
                        this.$router.push({ path: '/pages/ViewPending' });
                    else if (response.data.booking_details.booking_type == "Courier" || response.data.booking_details.booking_type == "Messenger")
                        this.$router.push({ path: '/pages/ViewCourierPending' });
                    else
                        this.$router.push({ path: '/pages/ViewEncodePending' });

                    this.save_btn.disabled = false;
                    this.save_btn.value = "SAVE";
                }
                catch (error) {
                    console.log(error);
                    this.save_btn.disabled = false;
                    this.save_btn.value = "SAVE";
                }

            } else {
                
                let url = "/api/courierbooking/createupdatebookingdetails";
                if (this.booking_id) {
                    url += ("/" + this.booking_id);
                }

                try {
                    const response = await this.axios.post(url, this.bookingdetail);
                    this.toast.success(response.data.msg);
                    if (this.booking_id) {
                        if (response.data.booking_details.fromOutbound) {
                            this.fetched_booking = response.data.booking_details;
                            return;
                        }
                        else if (response.data.booking_details.booking_type == "Mailroom") {
                            this.$router.push({ path: '/pages/ViewPending' });
                        }
                        else if (response.data.booking_details.booking_type == "Courier" || response.data.booking_details.booking_type == "Messenger") {
                            this.$router.push({ path: '/pages/ViewCourierPending' });
                        }
                        else {
                            this.$router.push({ path: '/pages/ViewEncodePending' });
                        }
                    } else {
                        this.fetched_booking = response.data.booking_details;
                    }
                    this.save_btn.disabled = false;
                    this.save_btn.value = "SAVE";
                }
                catch (error) {
                    console.log(error);
                    this.save_btn.disabled = false;
                    this.save_btn.value = "SAVE";
                }

            }
        }
    },
    watch: {
        'bookingdetail.delivery_branch'() {
            this.fetchDepartment();
        },
        'bookingdetail.delivery_department'() {
            this.fetchDeliveryLocation();
        }
    }
};
</script>
<style lang="scss">input[type=text]:focus,
select:focus {
    border: 2px solid;
    border-color: #06a5ed !important
}</style>